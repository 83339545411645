import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import TwitterIcon from "../../../assets/images/icons/Twitter.png";
import FacebookIcon from "../../../assets/images/icons/Facebook.png";
import InstagramIcon from "../../../assets/images/icons/instagram.png";
import SmoothScroll from "../../../../utils/Helpers/SmoothScroll";
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";

import coltonIcon from "../../../assets/images/colton.png";

import { FormattedMessage } from "react-intl";

const Footer = ({ language, changeLanguage }) => {
     const languageOptions = () => {
          return (
               <>
                    <option value={"ES"} key={"ES"}>
                         {"Spanish"}
                    </option>
               </>
          );
     };

     const onChangeLanguage = (e) => {
          changeLanguage(e.target.value);
          SmoothScroll("root");
     };

     const socialIcons = (
          <div className="social-icons mb-2">
               <a href="https://twitter.com/coltonutilities" target="_blank" rel="noopener noreferrer">
                    <img className="social-media-icon" alt="Twitter Icon" src={TwitterIcon} />
               </a>
               <a href="https://www.facebook.com/ColtonUtilities" target="_blank" rel="noopener noreferrer">
                    <img className="social-media-icon" alt="Facebook Icon" src={FacebookIcon} />
               </a>
               <a href="https://www.instagram.com/city_of_colton_electric/" target="_blank" rel="noopener noreferrer">
                    <img className="social-media-icon" alt="Instagram Icon" src={InstagramIcon} />
               </a>
          </div>
     );

     return (
          <div className="Footer">
               <div className="container pt-2 pb-4">
                    <div className="row">
                         <div className="col-md-2">
                              <img
                                   src={coltonIcon}
                                   className="img-fluid"
                                   alt="Colton Electric Logo"
                                   style={{ maxWidth: "150px", paddingRight: "40px" }}
                              />
                         </div>
                         <div className="col-md-2 my-auto">
                              <div className="row">
                                   {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (
                                        <>
                                             <Link to="/vehicles" className="">
                                                  <FormattedMessage
                                                       id="electricVehicles"
                                                       defaultMessage="Electric Vehicles"
                                                       description="Electric Vehicles Footer"
                                                       values={{
                                                            electricVehicles:
                                                                 process.env.REACT_APP_PAGES_VEHICLES_TITLE,
                                                       }}
                                                  />
                                             </Link>
                                        </>
                                   ) : null}
                              </div>
                              <div className="row">
                                   {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (
                                        <>
                                             <Link to="/compare-vehicles" className=" ">
                                                  <FormattedMessage
                                                       id="compareVehicles"
                                                       defaultMessage="Compare Vehicles"
                                                       description="Compare Vehicles Footer"
                                                       values={{
                                                            compareVehicles: process.env.REACT_APP_PAGES_COMPARE_TITLE,
                                                       }}
                                                  />
                                             </Link>
                                        </>
                                   ) : null}
                              </div>
                         </div>

                         <div className="col-md-2 my-auto">
                              <div className="row">
                                   {process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (
                                        <>
                                             <Link to="/charging-stations" className="  ">
                                                  <FormattedMessage
                                                       id="chargingStations"
                                                       defaultMessage="Charging Stations"
                                                       description="Charging Stations Footer"
                                                       values={{
                                                            chargingStations:
                                                                 process.env.REACT_APP_PAGES_CHARGING_TITLE,
                                                       }}
                                                  />
                                             </Link>
                                        </>
                                   ) : null}
                              </div>
                              <div className="row">
                                   {process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED ? (
                                        <>
                                             <Link to="/home-chargers" className="">
                                                  <FormattedMessage
                                                       id="homeChargers"
                                                       defaultMessage="Home Chargers"
                                                       description="Home Chargers Footer"
                                                       values={{
                                                            homeChargers:
                                                                 process.env.REACT_APP_PAGES_HOME_CHARGERS_TITLE,
                                                       }}
                                                  />
                                             </Link>
                                        </>
                                   ) : null}
                              </div>
                         </div>
                         <div className="col-md-2 my-auto">
                              <div className="row">
                                   {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (
                                        <>
                                             <Link to="/incentives" className=" ">
                                                  <FormattedMessage
                                                       id="incentives"
                                                       defaultMessage="Incentives"
                                                       description="Incentives Footer"
                                                       values={{
                                                            incentives: process.env.REACT_APP_PAGES_INCENTIVES_TITLE,
                                                       }}
                                                  />
                                             </Link>
                                        </>
                                   ) : null}
                              </div>
                              <div className="row">
                                   {process.env.REACT_APP_PAGES_DEALERS_ENABLED ? (
                                        <>
                                             <Link to="/dealers" className=" ">
                                                  <FormattedMessage
                                                       id="dealers"
                                                       defaultMessage="Dealers"
                                                       description="Dealers Footer"
                                                       values={{
                                                            dealers: process.env.REACT_APP_PAGES_DEALERS_TITLE,
                                                       }}
                                                  />
                                             </Link>
                                        </>
                                   ) : null}
                              </div>
                              {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
                                   <>
                                        <Link to="/faq">{process.env.REACT_APP_PAGES_FAQ_TITLE}</Link>
                                        <br />
                                   </>
                              ) : null}
                         </div>
                         <div className="col-md-2 my-auto">
                              <div className="row social-media-row">{socialIcons}</div>
                         </div>
                         <div className="col-md-2 my-auto">
                              <select
                                   className="form-control select-dte"
                                   value={language}
                                   onChange={onChangeLanguage}
                                   aria-label="Select Language"
                              >
                                   <option defaultValue="EN" value={"EN"}>
                                        English
                                   </option>
                                   {languageOptions()}
                              </select>
                         </div>
                    </div>
               </div>
               <div className="container-fluid">
                    <div className="container pb-4">
                         <div className="disclaimer-container">
                              <DisclaimerComponent clientName="zappynobackground" />
                         </div>
                    </div>
               </div>
          </div>
     );
};

export default Footer;
