import fetchWrapper from "../utils/fetch/fetchWrapper";
const fetchElectricians = async params => {
    // Remove null params
    Object.keys(params).forEach(key => params[key] == null && delete params[key]);
  
    let url = new URL(`${process.env.REACT_APP_API_HOST}/electricians`);
  
    let searchParams = new URLSearchParams(params);
  
    url.search = searchParams;
  
     const json = await fetchWrapper(url, {
       method: "GET",
     });
     return json.electricians;
  };
  
  export default fetchElectricians;
  